import React from 'react';
import { Link } from 'react-router-dom';
import MenuPerfil from '../Menu';
// import boton from '../../IMG/Botones/megusta.png';
// import boton2 from '../../IMG/Botones/compartir.png';
// import boton3 from '../../IMG/Botones/Like_OFF.png';
import Footer from '../Footer';
import '../../Component/styles/DetEventos.css';
import A from '../../IMG/Eventos/EVNT_M1.png';
import logo from '../../IMG/BFISKUR/Imagen3.png';
import B1 from '../../IMG/BFISKUR/B1.png';
import B2 from '../../IMG/BFISKUR/B2.png';

import {
  Modal, ModalHeader, ModalFooter, ModalBody, Button
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";
import ReactGa from 'react-ga';
import fot1 from "../../IMG/Eventos/HE.png";
import fot2 from '../../IMG/Eventos/Ev4.png';
import Carrusel from '../CarruselServi3';
import Carrusel2 from '../CarruselServi4';
import { Reveal, Tween } from 'react-gsap';


class Evento3 extends React.Component {
  // Modal registrarse y compartir


  state = {
    abierto: false,
  };




  abrirModal = () => {
    this.setState({ abierto: !this.state.abierto });
  }
  
  // ----------------------------

  componentDidMount = async () => {
    ReactGa.initialize('UA-258737016-1');
    ReactGa.pageview('Servicios OcaConsultoria 3');
  }




  render() {



    return (
      <>


        {/* Modal Registrar */}



        {/* ----------------- */}

          {/* <MenuPerfil /> */}
        <main role="main">
          <div className="container align-items-center">
            <div class="row">

              <div class="col-sm-6">
                <div className="card-body">

                  <p className="card-title TpLetraSNT" style={{lineHeight:'1.5'}}>Bfiskur</p>
                  <p class="card-text">
                    <p style={{lineHeight:'1.5'}}>En <strong>OCA Consultoría</strong> ofrecemos soluciones para medir tu nivel de riesgo fiscal desde la óptica del SAT. </p>
                    <ul>
                      <li>
                        <p style={{lineHeight:'1.5'}}><strong>Detecta discrepancias fiscales entre tus CFDIs timbrados vs Declaraciones vs. Contabilidad electrónica.</strong></p>
                      </li>
                      <li>
                        <p style={{lineHeight:'1.5'}}><strong>Se sincroniza con el portal del SAT y buzón tributario, ellos ya no tienen otros datos.</strong></p>
                      </li>
                    </ul>
                    {/* <p><strong>HORA DE CONFERENCIA:</strong> De 10:00 a 19:00 hrs</p> */}
                  </p>
                  {/* <div class="bd-highlight"><Link onClick={this.mostrarModalRegistrar} type="button" class="btn btn-success" >ENTRAR A LA CONFERENCIA</Link></div> */}
                  <hr color="#7E80A9" />
                </div>
              </div>
              <div class="container col-sm-6 align-items-center">
                <Reveal repeat>
                  <Tween
                    from={{ y: '25%', opacity: -20, rotation: 1 }}
                    to={{ y: '0', opacity: 10, rotation: 0 }}
                    duration={1.4}
                  >
                    <img src={B1} className="" width="24%" height="300px"></img>
                    {/* <img src={B2} className="" width="70%"></img> */}
                  </Tween>
                  <Tween
                    from={{ y: '-25%', opacity: -20, rotation: 1 }}
                    to={{ y: '0', opacity: 10, rotation: 0 }}
                    duration={1.4}
                  >
                    <img src={B2} className="" width="70%" height="300px"></img>
                  </Tween>
                </Reveal>
              </div>
              {/* <div class="container"> */}
                {/* <div class="d-flex flex-row-reverse bd-highlight"> */}
                  {/* <div class="p-2 bd-highlight mt-2"><Link to={'/ServiciosOCA4/'} type="button" class="btn btn-info"> SIGUIENTE &gt;</Link></div> */}
                  {/* <div class="p-2 bd-highlight align-items-left mr-auto"> */}
                    {/* <div class="p-2 bd-highlight"><Link to={'/ServiciosOCA2/'} type="button" class="btn" style={{backgroundColor:'#7E80A9'}}> &lt; REGRESAR</Link></div> */}
                    {/* <input class='' type="image" src={boton2} width="30" height="30"></input> */}
                  {/* </div> */}
                {/* </div> */}
              {/* </div> */}
            </div>
            <div class="container">
              <p className="card-title TpLetraSNT">¿Comó trabaja?</p>
              <p className="card-text text-justify pb-4 parrafo">
                Ofrece una solución de analítica de datos que trabaja a tu favor y detecta automáticamente discrepancias, que representan un riesgo fiscal para tu empresa.
              </p>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Carrusel />
              </div>
              <div className="col-sm-6">
                <Carrusel2 />
              </div>

            </div>
          </div>
        </main>
        {/* <Footer /> */}

      </>

    )
  }

}


export default Evento3;