import React from 'react';
import { Link } from 'react-router-dom';
import MenuPerfil from '../Menu';
import Footer from '../Footer';
import Evento2 from './Evento2';
import Bfiskur from './Evento3';
import '../../Component/styles/DetEventos.css';
import {
  Modal, ModalHeader, ModalFooter, ModalBody, Button, Jumbotron,
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col, CardBody,
  CardSubtitle, CardLink
} from 'reactstrap';
import classnames from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";
import ReactGA from 'react-ga4';
import ImaOCA1 from "../../IMG/OCA/1.jpg";
import ImaOCA2 from "../../IMG/OCA/2.jpg";
import ImaOCA3 from "../../IMG/OCA/3.jpg";
import { Reveal, Tween } from 'react-gsap';
import OCAlo from '../../IMG/OCA/logo192.png'
import CarruselServ from "../../paginas/CarruselServicios"
import LectorCB from '../../IMG/Servicios/Punto de venta/Lector de codigo.jpg'
import Miniprinter from '../../IMG/Servicios/Punto de venta/Miniprinter.jpg'
import Cajon from '../../IMG/Servicios/Punto de venta/Cajon de dinero.jpg'
import PuntoADM from '../../IMG/Servicios/Punto de venta/PuntodeVenta.jpg'
import PVAspel from '../../IMG/Servicios/Punto de venta/PuntoDeVentaAspel.jpg'
import CusrosAspel from './CursosAspel';
import FormatosAs from '../../IMG/Servicios/Reportes/Formato.mp4'
import ManOCA from '../../IMG/Servicios/Mantenimiento/ManOCA.png'

class Evento1 extends React.Component {


  // Modal registrarse y compartir
  state = {
    abierto: false,
  };

  abrirModal = () => {
    this.setState({ abierto: !this.state.abierto });
  }
  // ----------------------------

  componentDidMount = async () => {
    ReactGA.initialize('G-LV1HVF1Y4N');
    ReactGA.send({ hitType: "pageview", page: "/ServiciosOCA", title: "Servicios OCA" });
    // ReactGa.send('Servicios OcaConsultoria 1');
  }

  /*AQUI SE IMPLEMETA LA FUCNION DEL LOBY DEL CORAZÓN*/
  constructor(props) {
    super(props);
    this.state = { imagen: true };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '6'
    };
  }

  handleClick() {
    this.setState(prevState => ({
      imagen: !prevState.imagen
    }));
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {

    return (
      <>
        <MenuPerfil />
        <center>
          <div className="Container-Servicios">
            <p className="pt-5"></p>
            <p className="pt-4"></p>
            {/* TAPS */}
            <Nav tabs style={{ backgroundColor: '#00006A' }} className="justify-content-center">
              <NavItem >
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  Seguridad
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Infraestructura
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  Bfiskur
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  Timbres de sellado CDFI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  Punto de Venta
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => { this.toggle('6'); }}
                >
                  Cursos Sistemas Aspel
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '7' })}
                  onClick={() => { this.toggle('7'); }}
                >
                  Mantenimiento a equipos de computo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '8' })}
                  onClick={() => { this.toggle('8'); }}
                >
                  Formatos y reportes personalizados
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '9' })}
                  onClick={() => { this.toggle('9'); }}
                >
                  Conexiones remotas
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Evento2 />
                  </Col>
                  {/* <Col sm="6">
                  <Card body>
                    <CardTitle>Special Title Treatment</CardTitle>
                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                    <Button>Go somewhere</Button>
                  </Card>
                </Col> */}
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <center>
                      <div className="ConServicios ">
                        <div class="row">
                          <div class="col-sm-6">
                            <div className="card-body MS18">
                              <Jumbotron className="p-3">
                                <h1 className="display-4 text-center InfraJumbo" >INFRAESTRUCTURA TECNOLÓGICA</h1>
                              </Jumbotron>
                              <p class="card-text">
                                <p className="py-3">En <strong>OCA Consultoría</strong> montamos infraestructura tecnológica a tu medida: </p>
                                <ul>
                                  <li className="py-1" align="left">
                                    <p><strong>Servidores.</strong></p>
                                  </li>
                                  <li className="py-1" align="left">
                                    <p><strong>Route's, switch's.</strong></p>
                                  </li>
                                  <li className="py-1" align="left">
                                    <p><strong>Software y hardware a la medida.</strong></p>
                                  </li>
                                  <li className="py-1" align="left">
                                    <p><strong>Reguladores de voltaje, UPS No breaks.</strong></p>
                                  </li>

                                  <li className="py-1" align="left">
                                    <p><strong>Equipo de cómputo, micro, CPU´s, All-In One, etc.</strong></p>
                                  </li>
                                </ul>
                              </p>
                              <hr color="#61dafb" />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <p className="py-2"></p>
                            <div className="row">
                              <div class="col-12" style={{ backgroundColor: 'transparent' }}>
                                <div className="ConIn1">
                                  <Reveal repeat>
                                    <Tween
                                      from={{ x: '0px', opacity: -20, rotation: -5 }}
                                      to={{ x: '0px', opacity: 10, rotation: 0 }}
                                      duration={1.5}
                                    >
                                      <img src={ImaOCA1} className="" width="33%" height="340px" alt="" />
                                    </Tween>
                                    <Tween
                                      from={{ opacity: -9, rotation: 0.9 }}
                                      to={{ opacity: 1, rotation: 0 }}
                                      duration={1.5}
                                    >
                                      <img src={ImaOCA2} className="" width="33%" height="340px" alt="" />
                                    </Tween>
                                    <Tween
                                      from={{ x: '0px', opacity: 0, rotation: 5 }}
                                      to={{ x: '0px', opacity: 1, rotation: 0 }}
                                      duration={1.5}
                                    >
                                      <img src={ImaOCA3} className="" width="33%" height="340px" alt="" />
                                    </Tween>
                                  </Reveal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="container">
                          <Reveal repeat>
                            <Tween
                              from={{ x: '-90px', opacity: 0, rotation: 1 }}
                              to={{ x: '0px', opacity: 1, rotation: 0 }}
                              duration={1.5}
                            >
                              <h5 className="card-title pt-3">¿Cómo nos ayuda?</h5>
                            </Tween>
                          </Reveal>
                          <p className="card-text text-justify pb-4 parrafo">
                            Un elemento fundamental de una organización es su infraestructura tecnológica. Funcionalidad en software y hardware garantizan una óptima gestión interna en el almacenamiento de los datos en una empresa.
                            Estos son algunos de los productos que ofrecemos:
                          </p>
                        </div>
                        <CarruselServ className="col-sm-11" />
                      </div>
                    </center >
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <Bfiskur />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <div className="PdVServicios">
                  <img
                    alt="Card cap"
                    src={PVAspel}
                    width="100%"
                  />
                  <p className="PdVServiciosTexto">Te asesoramos a<br /> como administrar <br />de forma <strong>exitosa</strong><br /> tu punto de venta</p>
                </div>
                <Row style={{ border: 'dashed 1px' }}>
                  <Col align="center" className="col-12 col-sm-3 col-lg-3 col-xl-3 col-xxl-3">
                    <Card
                      style={{
                        width: '18rem',
                        border: 'transparent'
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5">
                          Lectores de codigos de barras
                        </CardTitle>
                        <CardSubtitle
                          className="mb-2 text-muted"
                          tag="h6"
                        >
                        </CardSubtitle>
                      </CardBody>
                      <img
                        alt="Card cap"
                        src={LectorCB}
                        width="100%"
                      />
                      <CardBody>
                        <CardText>
                          {/* L */}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12 col-sm-3 col-lg-3 col-xl-3 col-xxl-3" align="center">
                    <Card
                      style={{
                        width: '18rem',
                        border: 'transparent'
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5">
                          Impresoras térmicas
                        </CardTitle>
                        <CardSubtitle
                          className="mb-2 text-muted"
                          tag="h6"
                        >
                        </CardSubtitle>
                      </CardBody>
                      <img
                        alt="Card cap"
                        src={Miniprinter}
                        width="100%"
                      />
                      <CardBody>
                        <CardText>
                          {/* T */}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12 col-sm-3 col-lg-3 col-xl-3 col-xxl-3" align="center">
                    <Card
                      style={{
                        width: '18rem',
                        border: 'transparent'
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5">
                          Cajón de dinero
                        </CardTitle>
                        <CardSubtitle
                          className="mb-2 text-muted"
                          tag="h6"
                        >
                        </CardSubtitle>
                      </CardBody>
                      <img
                        alt="Card cap"
                        src={Cajon}
                        width="100%"
                      />
                      <CardBody>
                        <CardText>
                          {/* C */}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12 col-sm-3 col-lg-3 col-xl-3 col-xxl-3" align="center">
                    <Card
                      style={{
                        width: '18rem',
                        border: 'transparent'
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5">
                          Punto de venta Aspel-ADM
                        </CardTitle>
                        <CardSubtitle
                          className="mb-2 text-muted"
                          tag="h6"
                        >
                        </CardSubtitle>
                      </CardBody>
                      <img
                        alt="Card cap"
                        src={PuntoADM}
                        width="100%"
                      />
                      <CardBody>
                        <CardText>
                          {/* C */}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                    <CusrosAspel />
                    {/* <video src={video} width="100%" height="280" loop controls muted autoplay="true" preload="auto"></video> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row>
                  <Col sm="12">
                  <img src={ManOCA} className="" width="100%" alt="" />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="8">
                <Row>
                  <Col sm="12">
                    <video className="VideoFormatos py-1" src={FormatosAs} loop muted autoplay="true" preload="auto"></video>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            {/* TAPS */}
          </div>
        </center>
        <Footer />

      </>

    )
  }

}


export default Evento1;
