import React, { Component } from "react";
import video from '../IMG/Jumbotron/Vid_1.png';
import logo from '../IMG/Logo/oca-consultoria-logo-ii.png';
import fondo from '../IMG/Logo/12563-tecnologia.jpg';
import Botsito from '../IMG/Logo/png-clipart-person-wearing-headset-illustration-help-desk-technical-support-customer-service-business-3d-sketch-technical-support-text-hand-thumbnail.png';
import Bot from '../IMG/Logo/AAA.png';
import {
  Card, Button, CardImg, CardTitle, CardDeck,
  CardSubtitle, CardBody, Modal, ModalHeader, ModalFooter, ModalBody,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Reveal, Tween } from 'react-gsap';
import CarruselInicio from '../paginas/Carrusel OCA/CarruselInicio';

import "../Component/styles/Jumbotron.css";


class JInicio extends Component {

  
  render() {

    return (
      <>
        {/* Creacion de Modales*/}
        <center>
                  <CarruselInicio />

          <div class="card col-sm-12 border-1" style={{ backgroundColor: '#1C1853', borderColor: '#292563' }}>
            {/* .°..°..°..°..°..°..°..°..°..°..°..°..°..°..°. */}
            {/* ฅʕ•̫͡•ʔฅ */}
            ᕦ(ò_óˇ)ᕤ
            {/* <div>
              <div class="card-body">
                <div class="card border-1 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#1C1853', borderColor: '#1C1853', borderStyle: 'dashed' }}>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="em1 ">
          </div> */}
          <br />
        </center>

      </>
    );
  }
}

export default JInicio;
