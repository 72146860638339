import React from 'react';
import Formulario from './Formulario'
import Logo from '../IMG/Logo/Logo4_Evenet.png'
import Lo from '../IMG/Logo/SiigoAspel.png';
import '../Component/styles/Menu.css'
import { Link } from 'react-router-dom';
import { Reveal, Tween } from 'react-gsap';
import {
  Card, Button, CardImg, CardTitle, CardDeck,
  CardSubtitle, CardBody, Modal, ModalHeader, ModalFooter, ModalBody,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook, faInstagram, faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import Bot from '../IMG/Logo/AAA.png';
import logo from '../IMG/ADM/oca-consultoria-logo-ii.png';
import "../Component/styles/Jumbotron.css";
import { FormGroup, Label, Input } from 'reactstrap';
import IG from '../IMG/footer/IG.png'
import FormularioSNT from '../paginas/Formulario/Conexion';
import cANACOADM from '../IMG/ADM/CANACO.png';
import gNEWADM from '../IMG/ADM/GNEW.png';

class Menu2 extends React.Component {

  state = {
    abierto: false,
    abierto2: false,
  }
  abrirModal = () => {
    this.setState({ abierto: !this.state.abierto });
  }
  abrirModal2 = () => {
    this.setState({ abierto2: !this.state.abierto2 });
  }


  render() {

    return (
      <>

        {/*1.- Modal Evenet */}
        <Modal isOpen={this.state.abierto} size="m">
          <ModalHeader className="bg-dark text-center" align="center">
            <h2 style={{ color: 'white', marginLeft: '80%' }} className="text-center" align="center">Contáctanos</h2>
          </ModalHeader>
          <ModalBody>
            <h7 className="container">Contacta con nosotros, es muy fácil, déjanos tus datos y nos comunicaremos contigo.</h7>
            <FormularioSNT />

            <h5 align="center">Buscanos en redes sociales </h5>
            <div class="text-left px-3 text-center">
              <a href="https://www.facebook.com/ocaconsultoriaenti" target="_blank" style={{ fontSize: '40px' }}>
                {/* <FontAwesomeIcon icon={faFacebook} /> */}
              </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Facebook</p>
              <a href="https://www.instagram.com/oca.consultoria/" target="_blank" style={{ fontSize: '40px' }}>
                {/* <FontAwesomeIcon icon={faInstagram} /> */}
              </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Instagram</p>
              <a href="https://wa.me/5569074817" target="_blank" style={{ fontSize: '40px' }}>
                {/* <FontAwesomeIcon icon={faWhatsappSquare} /> */}
              </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Whatsapp</p>
            </div>
          </ModalBody>

          <ModalFooter>
            {/* <Button color="primary">Enviar</Button> */}
            <Button color="secondary" onClick={this.abrirModal}>Cerrar</Button>
          </ModalFooter>
        </Modal>

        <container>
          <nav class="cambioC ColorFondoMenu2 navbar navbar-expand-lg navbar-light fixed-top py-2 container col-11 shadow">
            <div className="ligIma">
                <a href="/"><img class="navbar-brand" src={logo} width="50%" /></a> 
                <img class="navbar-brand" src={cANACOADM} width="3%" height="50px" style={{position:'absolute'}}/>
              {/* <div>

                <img class="navbar-brand" src={gNEWADM} width="15%" />
              </div> */}
              <br />
            </div>
            <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>


            <div class=" navbar-collapse" id="navbarTogglerDemo02">
              <ul class="navbar-nav ml-auto col-3 col-sm-2 col-lg-3 col-xl-3 col-xxl-3">
                <li class="nav-item">
                  {/* <img class="navbar-brand" src={CANACOADM} width="35%" /> */}
                  {/* <a class="nav-link cambioC821 letraSNT8211" href="/Canaco" style={{color:'white'}}> <b> CANACO </b> </a> */}
                </li>
              </ul>
            </div>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a className="nav-link cambioC letraSNT" href="/" > <b> Inicio </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/SistemasAspel" > <b> Sistemas Aspel </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/ServiciosOCA" > <b> Servicios </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/Contacto" > <b> Contacto </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/Nosotros"> <b> Nosotros </b> </a>
                </li>
              </ul>
            </div>
          </nav>
        </container>

        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <Formulario />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  }

}

export default Menu2;