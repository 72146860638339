import React from 'react';
import { Link } from 'react-router-dom';
import MenuPerfil from '../Menu';
// import boton from '../../IMG/Botones/megusta.png';
// import boton2 from '../../IMG/Botones/compartir.png';
// import boton3 from '../../IMG/Botones/Like_OFF.png';
import Footer from '../Footer';
import '../../Component/styles/DetEventos.css';
import A from '../../IMG/Eventos/EVNT_M1.png';
import {
  Modal, ModalHeader, ModalFooter, ModalBody, Button, Jumbotron
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";
import ReactGa from 'react-ga';
import fot1 from "../../IMG/Eventos/HE.png";
import fot2 from '../../IMG/Eventos/Ev4.png';
import CarruselSer from '../../paginas/CarruselServi2'
import CarruselServi2 from '../../paginas/CarruselServi2';


class Evento2 extends React.Component {
  // Modal registrarse y compartir


  state = {
    abierto: false,
  };




  abrirModal = () => {
    this.setState({ abierto: !this.state.abierto });
  }
  // ----------------------------

  componentDidMount = async () => {
    ReactGa.initialize('UA-258737016-1');
    ReactGa.pageview('Servicios OcaConsultoria 2');
  }




  render() {



    return (
      <>


        {/* Modal Registrar */}



        {/* ----------------- */}

        <main role="main">
          {/* <MenuPerfil /> */}

          <div className="container align-items-center">
            <div class="row">

              <div class="col-sm-6">
                <div className="card-body MS18" style={{lineHeight:'1.5em'}}>
                  <Jumbotron className="p-3">
                    <h1 className="display-4 text-center InfraJumbo" >SEGURIDAD PERIMETRAL</h1>
                  </Jumbotron>
                  <p className="card-title TpLetraSNT" ></p>
                  <p class="card-text" style={{lineHeight:'1.5em'}}>
                    <p>En <strong>OCA Consultoría</strong> ofrecemos medidas de protección que preveen ataques externos. </p>
                    <ul>
                      <li>
                        <p><strong>Protege y aísla actividad desconocida o fraudulenta.</strong></p>
                      </li>
                      <li>
                        <p><strong>Método de defensa de red.</strong></p>
                      </li>
                      <li>
                        <p><strong>Información segura.</strong></p>
                      </li>
                    </ul>
                    {/* <p><strong>HORA DE CONFERENCIA:</strong> De 10:00 a 19:00 hrs</p> */}
                  </p>
                  {/* <div class="bd-highlight"><Link onClick={this.mostrarModalRegistrar} type="button" class="btn btn-success" >ENTRAR A LA CONFERENCIA</Link></div> */}
                  <hr color="#61dafb" />
                </div>
              </div>
              <div class="container col-sm-6 align-items-center">
                <CarruselServi2 />
                {/* <img src={fot2} className="d-block IDE1"></img>*/}
              </div>
              {/* <div class="container"> */}
                {/* <div class="d-flex flex-row-reverse bd-highlight"> */}
                  {/* <div class="p-2 bd-highlight mt-2"><Link to={'/ServiciosOCA3/'} type="button" class="btn" style={{ backgroundColor: '#0096A2' }}> SIGUIENTE &gt;</Link></div> */}
                  {/* <div class="p-2 bd-highlight align-items-left mr-auto"> */}
                    {/* <div class="p-2 bd-highlight"><Link to={'/ServiciosOCA/'} type="button" class="btn" style={{ backgroundColor: '#0096A2' }}> &lt; REGRESAR</Link></div> */}
                    {/* <input class='' type="image" src={boton2} width="30" height="30"></input> */}
                  {/* </div> */}
                {/* </div> */}
              {/* </div> */}
            </div>
            <div class="container" style={{lineHeight:'1.5em'}}>
              <p className="card-title TpLetraSNT">¿Cómo nos ayuda?</p>
              <p className="card-text text-justify pb-4 parrafo">
                La seguridad perimetral en una empresa es muy importante. Los ataques por red y pérdidas de información afectan el desarrollo de la empresa, es por esta razón que es fundamental asegurar nuestra información. <br /> <br />
              </p>
            </div>
          </div>
        </main>
        {/* <Footer /> */}

      </>

    )
  }

}


export default Evento2;