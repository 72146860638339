import React from 'react';
import InfraTec from '../IMG/SolucionesTecnologicas/OCA_ST_4.jpg';
import SistBack from '../IMG/SolucionesTecnologicas/OCA_ST_3.jpg';
import SegPerim from '../IMG/SolucionesTecnologicas/OCA_ST_2.jpg';
import Bfiskur_1 from '../IMG/SolucionesTecnologicas/OCA_BF_1.jpg';
import Bfiskur_2 from '../IMG/SolucionesTecnologicas/OCA_BF_2.png';
import Bfiskur_3 from '../IMG/SolucionesTecnologicas/OCA_BF_3.png';
import Bfiskur_4 from '../IMG/SolucionesTecnologicas/OCA_BF_4.png';
import Bfiskur_5 from '../IMG/SolucionesTecnologicas/OCA_BF_5.png';
import Bfiskur_6 from '../IMG/SolucionesTecnologicas/OCA_BF_6.png';
import Bfiskur_7 from '../IMG/SolucionesTecnologicas/OCA_BF_7.png';
import SiigoAspel from '../IMG/SolucionesTecnologicas/OCA_SA_1.jpg';
import SiigoSAE from '../IMG/SolucionesTecnologicas/OCA_SA_2.png';
import SiigoNOI from '../IMG/SolucionesTecnologicas/OCA_SA_3.png';
import SiigoCOI from '../IMG/SolucionesTecnologicas/OCA_SA_4.png';
import SiigoBANCO from '../IMG/SolucionesTecnologicas/OCA_SA_5.png';
import LogoAspel from '../IMG/LogosAspel/LogoAspel.png';
// Importación de imagenes
import A1 from '../IMG/modal/icono-inf-tecnol-gica.png';
import L1 from '../IMG/LogosAspel/ASPEL-ICONO VERT_BANCO.png';
import L2 from '../IMG/LogosAspel/ASPEL-ICONO VERT_CAJA.png';
import L3 from '../IMG/LogosAspel/ASPEL-ICONO VERT_COI.png';
import L4 from '../IMG/LogosAspel/ASPEL-ICONO VERT_FACTURE.png';
import L5 from '../IMG/LogosAspel/ASPEL-ICONO VERT_NOI.png';
import L6 from '../IMG/LogosAspel/ASPEL-ICONO VERT_PROD.png';
import L7 from '../IMG/LogosAspel/ASPEL-ICONO VERT_SAE.png';
import A2 from '../IMG/modal/icon-backup.png';
import A3 from '../IMG/modal/icon-seguridad.png';
import BF1 from '../IMG/BFISKUR/cfdi-cancelados 1.png';
import BF2 from '../IMG/BFISKUR/comprobantes-pago-faltantesl 1.png';
import BF3 from '../IMG/BFISKUR/cumplimiento-obligaciones 1.png';
import BF4 from '../IMG/BFISKUR/efos 1.png';
import BF5 from '../IMG/BFISKUR/medidor-riesgo-fiscal 1.png';
import BF6 from '../IMG/BFISKUR/cfdi-recibido-reglas 1.png';
import BF7 from '../IMG/BFISKUR/repse-registro-proveedores 1.png';
import ServOCA from '../IMG/OCA/Servicios/ServiciosOCA.jpg';
import LogoAspel1 from '../IMG/LogosAspel/LogoAspel.png';
import '../Component/styles/Servicios.css';
import { Reveal, Tween } from 'react-gsap';
import B1 from '../IMG/Logo/Bfis.png';
import {
       Card, Button, CardImg, CardTitle, CardText, CardDeck,
       CardSubtitle, CardBody, CardFooter, CardHeader
} from 'reactstrap';
import ServiciosOCA from '../paginas/DetallesEventos/Evento1';

class Serv extends React.Component {

       render() {

              return (
                     <>
                            <br />
                            <div className="row">
                                   <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                                          <CardDeck className="SombraCARDSNT pb-3">
                                                 <Card style={{ backgroundColor: '#eff3fb' }}>
                                                        <div className="contene1">
                                                               <center>
                                                                      <a href="/ServiciosOCA">
                                                                             <img src={InfraTec} width="100%" className="Opaci" ></img>
                                                                      </a>
                                                               </center>
                                                               <div className="contene2">
                                                                      <p className="TitH9 py-1">
                                                                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Infraestructura ​Tecnológica &nbsp;&nbsp;&nbsp;
                                                                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                      </p>
                                                               </div>
                                                               <CardFooter className="text-center pb-4">
                                                                      <div className="SerIn">
                                                                             <span className="contenedor3">Somos una empresa especializada en tecnología de la ​información y consultoría.</span>
                                                                      </div>
                                                               </CardFooter>
                                                        </div>
                                                 </Card>
                                          </CardDeck>
                                          <CardDeck className="SombraCARDSNT">
                                                 <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                        <div className="contene1">
                                                               <center>
                                                                      <a href="/ServiciosOCA">
                                                                             <img src={SistBack} width="100%" className="Opaci" ></img>
                                                                      </a>
                                                               </center>
                                                               <div className="contene2">
                                                                      <p style={{ color: 'white' }} className="TitH9 py-2">
                                                                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sistema de Backup &nbsp;&nbsp;&nbsp;
                                                                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                      </p>
                                                               </div>
                                                               <CardFooter className="text-center">
                                                                      <span className="contenedor3">Respaldamos tu información de forma segura.</span>
                                                               </CardFooter>
                                                        </div>
                                                 </Card>
                                          </CardDeck>
                                   </div>

                                   <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                                          <CardDeck className="SombraCARDSNT ">
                                                 <Card style={{ backgroundColor: '#eff3fb' }}>
                                                        <CardBody className="pt-1" style={{ backgroundColor: '' }}>
                                                               <br />
                                                               <center>
                                                                      <a href="ServiciosOCA3">
                                                                             <img src={Bfiskur_1} width="65%" className="pb-2" ></img>
                                                                      </a>
                                                               </center>
                                                               <br />
                                                               <CardTitle tag="h1" align="center" style={{ color: 'black' }} className="pb-2">bFiskur</CardTitle>
                                                               <br />
                                                               <CardSubtitle align="center" className="pb-4"><p style={{ color: 'black' }} className="h8">​Evita notificaciones y multas del SAT.</p> </CardSubtitle>
                                                               <center className="pb-2">
                                                                      <Reveal repeat>
                                                                             <Tween
                                                                                    from={{ opacity: -10, rotation: -15 }}
                                                                                    to={{ opacity: 10, rotation: 0 }}
                                                                                    duration={1}
                                                                             >
                                                                                    <a href="ServiciosOCA3">
                                                                                           <img src={Bfiskur_2} width="31.5%" className="p-1" ></img>
                                                                                    </a>
                                                                             </Tween>

                                                                             <Tween
                                                                                    from={{ opacity: -10, rotation: -15 }}
                                                                                    to={{ opacity: 9, rotation: 0 }}
                                                                                    duration={1.5}
                                                                             >
                                                                                    <a href="ServiciosOCA3">
                                                                                           <img src={Bfiskur_3} width="33%" className="p-1" ></img>
                                                                                    </a>
                                                                             </Tween>
                                                                             <Tween
                                                                                    from={{ opacity: -10, rotation: -15 }}
                                                                                    to={{ opacity: 8, rotation: 0 }}
                                                                                    duration={2}
                                                                             >
                                                                                    <a href="ServiciosOCA3">
                                                                                           <img src={Bfiskur_4} width="33%" className="p-1" ></img>
                                                                                    </a>
                                                                             </Tween>
                                                                             <br />
                                                                             <Tween
                                                                                    from={{ opacity: -10, rotation: -15 }}
                                                                                    to={{ opacity: 7, rotation: 0 }}
                                                                                    duration={2.5}
                                                                             >
                                                                                    <a href="ServiciosOCA3">
                                                                                           <img src={Bfiskur_5} width="33%" className="p-1" ></img>
                                                                                    </a>
                                                                             </Tween>
                                                                             <Tween
                                                                                    from={{ opacity: -10, rotation: -15 }}
                                                                                    to={{ opacity: 6, rotation: 0 }}
                                                                                    duration={3}
                                                                             >
                                                                                    <a href="ServiciosOCA3">
                                                                                           <img src={Bfiskur_6} width="33%" className="p-1" ></img>
                                                                                    </a>

                                                                             </Tween>
                                                                             <Tween
                                                                                    from={{ opacity: -10, rotation: -15 }}
                                                                                    to={{ opacity: 5, rotation: 0 }}
                                                                                    duration={3.5}
                                                                             >
                                                                                    <a href="ServiciosOCA3">
                                                                                           <img src={Bfiskur_7} width="33%" className="p-1 mb-3" ></img>
                                                                                    </a>
                                                                             </Tween>
                                                                      </Reveal>

                                                               </center>
                                                        </CardBody>
                                                 </Card>
                                          </CardDeck>
                                   </div>
                                   <div className="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4">
                                          <div className="row">
                                                 <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-xxl-12">
                                                        {/* Card Sistemas Aspel */}
                                                        <CardDeck className="SombraCARDSNT pb-3">
                                                               <Card style={{ backgroundColor: '#1C1853' }}>
                                                                      <div className="row">
                                                                             <div className="col-6">
                                                                                    <a href="/SistemasAspel">
                                                                                           <img src={LogoAspel} className="LogAspel" width="100%" height="250"></img>
                                                                                    </a>
                                                                             </div>
                                                                             <div className="col-6">
                                                                                    <div className="row">
                                                                                           <div className="col-6 ">
                                                                                                  <a href="/SistemasAspel">
                                                                                                         <img src={SiigoSAE} width="100%" className="" height="130"></img>
                                                                                                         <img src={SiigoCOI} width="100%" className="" height="130"></img>
                                                                                                  </a>
                                                                                           </div>
                                                                                           <div className="col-6">
                                                                                                  <div className="hhh" >
                                                                                                         <a href="/SistemasAspel">
                                                                                                                <img src={SiigoNOI} width="100%" className="" height="130"></img>
                                                                                                                <img src={SiigoBANCO} width="100%" className="" height="130"></img>
                                                                                                         </a>
                                                                                                  </div>
                                                                                           </div>
                                                                                           {/* <div className="col-1" >       
                                                                             </div> */}
                                                                                    </div>
                                                                             </div>
                                                                      </div>
                                                                      <CardBody className="pt-1 pb-3" style={{ backgroundColor: '#1C1853' }}>
                                                                             <br />
                                                                             <div className="TaSN">
                                                                                    <CardTitle align="center" style={{ color: 'white' }} ><span className="TitH8 ">Software Administrativo</span></CardTitle>
                                                                                    <br />
                                                                                    <CardSubtitle align="center" className="">
                                                                                           <p className="LetraCardSERV">SSAutomatizamos los procesos contables, administrativos, de facturación, punto de venta y nómina para tu empresa. OCA</p>
                                                                                    </CardSubtitle>
                                                                             </div>
                                                                             <br />
                                                                      </CardBody>
                                                               </Card>
                                                        </CardDeck>
                                                 </div>

                                                 <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-xxl-12">
                                                        {/* Card Seguridad Perimetral */}
                                                        <CardDeck className="SombraCARDSNT">
                                                               <Card style={{ backgroundColor: '#eff3fb' }}>
                                                                      <div className="contene1">
                                                                             <center>
                                                                                    <a href="">
                                                                                           <a href="/ServiciosOCA2">
                                                                                                  <img src={SegPerim} width="100%" className="Opaci" ></img>
                                                                                           </a>
                                                                                    </a>
                                                                             </center>
                                                                             <div className="contene2">
                                                                                    <p style={{ color: 'white' }} className="TitH9 py-2">
                                                                                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seguridad Perimetral &nbsp;&nbsp;&nbsp;
                                                                                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    </p>
                                                                             </div>
                                                                             <CardFooter className="text-center pb-3">
                                                                                    <span className="contenedor3">Protegemos tu información de ataques externos.</span>
                                                                             </CardFooter>
                                                                      </div>
                                                               </Card>
                                                        </CardDeck>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                            <br />
                     </>
              )
       }

}

export default Serv;