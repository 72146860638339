import React from 'react';
import ReactGA from 'react-ga4';
import Menu from './Menu';
import Slider from './Silider';
import Serv from './Serv';
import Footer from './Footer';
import '../Component/styles/Home.css';
import JInicio from './JInicio';
import CarruselPagina from './CarruselPagina';
import Acomercial from './Acomercial';
import video from '../Video/Aspel/139982168_3010897155680540_4507801383721697593_n.mp4';
import videoProAct from '../Video/CasosDeExitos/ProACT_OCAConsultoria.mp4'
import I4 from '../IMG/OCA/Cliente.jpg';
import Carrusel from './Carrusel';
import InicioModal from '../IMG/Avisos/ASEGURA.jpg';
import InicioModal2 from '../IMG/Avisos/bfi.jpg';
import '../Component/styles/Stand1.css';
import SolucionesTec from './SolucionesTec';
import '../Component/styles/Servicios.css';
// ReactGa.inicialize('G-LV1HVF1Y4N');

import {
  Modal, ModalHeader, ModalFooter, ModalBody, Card, Button,
  CardBody, CardTitle, CardText, CardGroup, CardFooter,
  Row, Col, CardDeck, CardImg, CardSubtitle, TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { Reveal, Tween } from 'react-gsap';
import CClientes from './CarruselClientes';
import AInteres from './AreasInteres';
// import TeamVi2 from '../IMG/TeamViewer/TeamViewer1.png';
import TeamVi2 from '../IMG/TeamViewer/Team.jpg';
import HomeReset from '../Component/styles/HomeReset.css';
import TeamViewerCH from './TeamViewer';
import btnADM from '../IMG/ADM/ADM.png';
import CarruselCANACOSNT from './Carrusel OCA/CarruselCANACO';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      InicioModal: false
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState({
      InicioModal: !this.state.InicioModal
    });
  }

  state = {
    modalInicio: true,
    modalCerrar: false,
  };

  mostrarModalInicio = () => {
    this.setState({
      modalInicio: true,
    });
  };
  cerrarModalInicio = () => {
    this.setState({
      modalInicio: false,
    });
  };


  //Registro en GA
  componentDidMount = async () => {
    ReactGA.initialize('G-LV1HVF1Y4N');
    ReactGA.send({ hitType: "pageview", page: "/Inicio", title: "Inicio" });
  }

  render() {


    return (
      <>


        <JInicio />
        {/* <ModalLoby /> manda a importar el modal */}
        <Menu /> {/*manda a importar el menu */}

        {/* <br />
        <br />
        <br />
        <br /> */}
        {/*Modal Avisos página principal */}
        <Modal isOpen={this.state.InicioModal} size="lg" toggle={this.toggle}
          aria-labelledby="example-modal-sizes-title-sm" centered >

          <button type="button" className="close US2" aria-label="Close" onClick={this.toggle} style={{ backgroundColor: '#C0C8DE' }}>
            <span aria-hidden="true">&times;</span>
          </button>
          <center style={{ backgroundColor: '#B2B6CF' }}>
            <img src={InicioModal} width="50%" className="p-0 m-0" />
            <img src={InicioModal2} width="50%" className="p-0 m-0" />
          </center>

        </Modal>
        {/*Termina Modal Avisos página principal */}
        {/* <JInicio />  */}
        {/* <div style={{ backgroundColor: '#e1e4eb', height: '45px', filter: 'drop-shadow(9px 8px 10px #0c0b0b)' }}> */}


        {/* <div className="container">
          <Reveal repeat>
            <Tween
              from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
              to={{ x: '0%', opacity: 1, rotation: 0 }}
              duration={1.1}
            >
              <h2 className="TitSolu" align="center"><b className="pru1">Siigo Aspel</b></h2>
            </Tween>
          </Reveal>
        </div>
        <div className="container-fluid pt-2">
          <center>
        <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fvideos%2F7347624128689137%2F&show_text=true&width=267&t=0" width="267" height="591" style={{border:'none', overflow: 'hidden'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
        </center>
        </div> */}

        {/* <div class="fb-share-button" data-href="https://www.facebook.com/share/p/wYcL47jPhSP13bMV/" data-layout="" data-size=""><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fp%2FwYcL47jPhSP13bMV%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Compartir</a></div> */}
        {/* <center>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fposts%2Fpfbid0ccpCvF7mRKNaXrd9RQhiuijp1fsWusT7c54dAbyBJ1TaSj1oGdEbvoUHWsToCbfBl&show_text=true&width=500" width="500" height="499" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fposts%2Fpfbid02k6bZPFfXK8h87cA3yxvK4CXhtCDe86iqDt4qRn7jEs95wxjGiU2Fb62WEQ5uCtsal&show_text=true&width=500" width="500" height="640" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fposts%2Fpfbid02dwv79LhbFsvi3sLpHajt8kiisGUKHMeDfCKELbdvT6inTytkL3LmsU1uN4rU7FSEl&show_text=true&width=500" width="500" height="690" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </center> */}
        <div className="container">
          <Reveal repeat>
            <Tween
              from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
              to={{ x: '0%', opacity: 1, rotation: 0 }}
              duration={1.1}
            >
              <h2 className="TitSolu" align="center"><b className="pru1">Soluciones tecnológicas de gestión empresarial </b></h2>
            </Tween>
          </Reveal>
        </div>
        <div className="container-fluid">
          <SolucionesTec />
        </div>
        <br /><br />  <br /><br />


        <div className=" container" style={{ filter: 'drop-shadow(9px 8px 10px #0c0b0b)' }}>
          <Reveal repeat>
            <Tween
              from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
              to={{ x: '0%', opacity: 1, rotation: 0 }}
              duration={1.1}
            >
              {/* <h2 ClassName="TitSolu fon TamaLetraServicios" align="center" ><b>Nuestros Servicios. </b></h2> */}
              <h2 className="TitSolu" align="center"><b className="pru1">Nuestros Servicios. </b></h2>
            </Tween>
          </Reveal>
        </div>
        <br />
        <br />
        <br />
        {/* <span className="pb-1">.</span> */}
        <center>
          <div className="cont">
            <div className="row">
              <div className="col-12">
                <Serv />
              </div>
            </div>
          </div>
        </center>

        <div className=" col-sm-12 FondoBot2 border-1 my-0 mb-4">
          <center>
            ᕦ(ò_óˇ)ᕤ
          </center>
        </div>
        <br /><br />
        <br /><br />



        <center>
          <div className=" cont2">

            <div className="row ">
              <div className="XD3 p-1 col-12 col-sm-12 col-lg-2 col-xl-2 col-xxl-2">
                <div class="card ">
                  <Acomercial />
                </div>
              </div>
              <div class="card px-3 ed-ancho col-12 col-sm-12 col-lg-10 col-xl-10 col-xxl-10" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }} >
                {/* <div style={{ backgroundColor: '#e1e4eb', height: '45px', filter: 'drop-shadow(9px 8px 10px #0c0b0b)' }}> */}
                <div>
                  <Reveal repeat>
                    <Tween
                      from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
                      to={{ x: '0%', opacity: 1, rotation: 0 }}
                      duration={1.1}
                    >
                      {/* <h2 ClassName="titSNT" align="center"><b>Soluciones tecnológicas de gestión empresarial </b></h2> */}
                      <h2 className=" TitSolu text-center"> CLIENTES SATISFECHOS </h2>
                    </Tween>
                  </Reveal>
                </div>
                {/* <CarruselPagina /> */}
                <div className="py-4">
                  <CClientes />
                </div>
                <Card style={{ backgroundColor: '#eff3fb' }}>
                  <div className="contenedor1">
                    <CardFooter className="text-center pb-4" id="CExito">
                      <h2 className="contenedor3 pb-4" style={{ filter: 'drop-shadow(9px 8px 10px #1163E5)' }}>EVENTOS OCA CONSULTORÍA EN T.I.</h2>
                      <span className="contenedor3">Somos una empresa especializada en tecnología de la ​información y consultoría.</span>
                    </CardFooter>
                  </div>
                </Card>
              </div>

              <center>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fposts%2Fpfbid0ccpCvF7mRKNaXrd9RQhiuijp1fsWusT7c54dAbyBJ1TaSj1oGdEbvoUHWsToCbfBl&show_text=true&width=500" width="500" height="499" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fposts%2Fpfbid02k6bZPFfXK8h87cA3yxvK4CXhtCDe86iqDt4qRn7jEs95wxjGiU2Fb62WEQ5uCtsal&show_text=true&width=500" width="500" height="640" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Focaconsultoriaenti%2Fposts%2Fpfbid02dwv79LhbFsvi3sLpHajt8kiisGUKHMeDfCKELbdvT6inTytkL3LmsU1uN4rU7FSEl&show_text=true&width=500" width="500" height="690" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </center>

              <div class=" XD3 p-1 justify-content-between SombraSNT col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12">
                <div class="card col-12 border-0 container-fluid my-4" style={{ backgroundColor: '#090965' }}>
                  <h6 >CASOS DE EXITO </h6>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4">
                    <video src={video} width="100%" height="330" loop controls muted autoplay="true" preload="auto"></video>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4">
                    <video src={videoProAct} width="100%" height="330" loop controls muted autoplay="true" preload="auto"></video>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4">
                    <center>
                      <img src={I4} className="" width="100%" height="330" ></img>
                    </center>
                  </div>

                  <div class="card col-12 border-0 container-fluid my-4" style={{ backgroundColor: '#090965' }}>
                    ᕦ(ò_óˇ)ᕤ
                  </div>
                </div>

              </div>
            </div>
            {/* <CClientes /> */}
          </div>
        </center>
        <div style={{ backgroundColor: '#e1e4eb', height: '45px', filter: 'drop-shadow(9px 8px 10px #0c0b0b)' }} className="mt-4">
          <Reveal repeat>
            <Tween
              from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
              to={{ x: '0%', opacity: 1, rotation: 0 }}
              duration={1.1}
            >
              <h2 className="TitSolu" align="center"><b className="pru1">Nuestros Clientes. </b></h2>
              {/* <h2 ClassName="titSNT" align="center" ><b>Nuestros Clientes. </b></h2> */}
            </Tween>
          </Reveal>
        </div>
        <div className="fondoClie">
          {/* <h3 align="center">NUESTROS CLIENTES</h3> */}
          <div className="center" align="center">
            <Carrusel />
          </div>
        </div>
        <div className="FondoBot3 container-fluid" style={{ backgroundColor: '#eff3fb', filter: 'drop-shadow(9px 8px 10px #0c0b0b)' }}>

          {/* Inicia sección para darle movimiento*/}
          <Reveal repeat>
            <Tween
              from={{ x: '-50px', opacity: 0, rotation: 0.5 }}
              to={{ x: '0px', opacity: 1, rotation: 0 }}
              duration={1.3}
            >
              <h2 className="TitSolu" align="center"><b className="pru1">ÁREAS DE INTERÉS </b></h2>
            </Tween>
          </Reveal>
          {/* Termina sección para darle movimiento*/}
          {/* <h2>ÁREAS DE INTERÉS</h2> */}
        </div>
        <br />
        <br /><br /><br />
        <AInteres />
        <center>
          <hr />
          {/* Sección CANACO */}
          <div ClassName="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">
                <Card className="btnCADM" color="primary" outline>
                  <div className="BorderIma py-1">
                    <a class="" href="/CanacoADM" >
                      <img src={btnADM} className=" text-center justify-content-center" width="100%" height="380px" outline color="primary"></img>
                    </a>
                  </div>
                </Card>
              </div>
              <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">
                <CarruselCANACOSNT />
              </div>
              <br />
            </div>
          </div>

          {/* Sección CANACO */}
          <hr />
          <TeamViewerCH />
        </center>
        {/* </div> */}
        <Footer />
      </>
    )
  }

}

export default Home;